<template>
  <v-sheet>

    <v-row>
      <v-col cols="2">
        <span>Alege una din variantele de mai jos:</span>
        <v-switch
            v-model="selected.target.recipient_type"
            color="primary"
            hide-details
            label="Clienti CIT Grup"
            value="citgrup_clients"
        ></v-switch>

        <v-switch
            v-model="selected.target.recipient_type"
            color="primary"
            hide-details
            label="Inscrisii la newsletter"
            value="newsletter_subscribers"
        ></v-switch>

        <!--        <v-switch-->
        <!--          v-model="selected.recipient_type"-->
        <!--          color="primary"-->
        <!--          hide-details-->
        <!--          label="Clientii Distribuitorilor"-->
        <!--          value="reseller_clients"-->
        <!--        ></v-switch>-->

      </v-col>
      <v-col cols="10">
        <v-row v-if="selected.target.recipient_type === 'citgrup_clients'">
          <v-col cols="3">
            <v-card class="h-full" elevation="2">
              <v-card-title>
                Grupuri de clienti pentru Citgrup
              </v-card-title>
              <v-card-text>
                <v-checkbox
                    v-for="(clientType, index) in clientTypes"
                    :key="index"
                    v-model="selected.target.citgrup_clients.client_type"
                    :label="`${clientType.name} (${clientType.count})`"
                    :value="clientType.machine_name"
                    class="mt-0"
                    color="teal"
                    hide-details
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card class="h-full" elevation="2">
              <v-card-title>
                Grupuri de clienți pentru francizați
              </v-card-title>
              <v-card-text>
                <v-checkbox
                    v-for="(clientType, index) in franchiseeClient"
                    :key="index"
                    v-model="selected.franchisee_target.citgrup_clients.client_type"
                    :label="`${clientType.name} (${clientType.count})`"
                    :value="clientType.machine_name"
                    class="mt-0"
                    color="teal"
                    hide-details
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card class="h-full" elevation="2">
              <v-card-title>
                Interese
              </v-card-title>
              <v-card-text>
                <v-checkbox
                    v-for="interest in interests.data"
                    :key="interest.id"
                    v-model="selected.target.citgrup_clients.interests"
                    :label="interest.name"
                    :value="interest.id"
                    class="mt-0 w-1/2 inline-block interest"
                    color="green"
                    hide-details
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-alert
                border="left"
                color="yellow"
                colored-border
                elevation="2"
            >
              La selectarea incrucisata (atat grupe cat si interese), newsletterul se va trimite la intersecia
              celor 2
              (utilizatorii din grupele bifate <strong>care au atribuite</strong> interesele bifate)
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-alert
                border="left"
                color="yellow"
                colored-border
                elevation="2"
            >
              Pentru newsletterul trimis din partea francizatilor <strong>interesele nu se vor lua</strong> in considerare
            </v-alert>
          </v-col>          
        </v-row>
      </v-col>
    </v-row>

  </v-sheet>
</template>

<script>
export default {
  name: 'ClientSelect',
  data () {
    return {
      interests: {
        data: []
      },
      clientTypes:[],
      franchiseeClient:[]
    }
  },
  props: {
    value: {},
  },
  computed: {
    selected: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  mounted () {
    this.$http.get('/newsletter/settings/client-types')
        .then(({data}) => {
          this.clientTypes = data
        })

    this.$http.get('/clients/interest-lists')
        .then(({data}) => {
          this.interests = data
        })

        this.$http.get('/newsletter/settings/franchiseeClient-types')
        .then(({data}) => {
          this.franchiseeClient = data
        })
  }
}
</script>

<style lang="scss">
.interest .v-label {
  font-size: 12px;
}
</style>
