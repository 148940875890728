<template>
  <section class="data-list-container">
    <v-card class="mb-4">
      <v-card-title>Setari Generale</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field v-model="form.subject" dense hide-details label="Subiect Newsletter" outlined />
            <error-messages :errors="errors.subject" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
                v-model="form.use_franchisee_names"
                color="primary"
                hide-details
                label="Foloseste numele francizatilor"
                :false-value=0
                :true-value=1
                :value="form.use_franchisee_names"
            ></v-switch>
          </v-col>
        </v-row>        
        <v-row>
          <v-col>
            <v-text-field v-model="form.reply_to" dense hide-details
              label="Reply to (Adresa la care se va putea raspunde)" outlined />
            <error-messages :errors="errors.reply_to" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="form.from_name" dense hide-details label="De la (Numele)" outlined />
            <error-messages :errors="errors.from_name" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-title>Selecteaza Produsele</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <products-select ref="productSelect" v-model="form.products" />
            <error-messages :errors="errors.products" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-4">
      <v-card-title>Audienta</v-card-title>
      <v-card-text>
        <client-select v-model="form" />
        <error-messages :errors="errors['target.recipient_type']" />
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-title>Editeaza newsletter</v-card-title>
      <v-card-text>
        <fields-editor v-model="form.fields" />
        <error-messages :errors="errors.fields" />
      </v-card-text>
    </v-card>


    <v-card class="mb-4">
      <v-card-title>Selecteaza Ora si data Trimiterii</v-card-title>
      <v-card-text>
        <sending-at-select v-model="form.sending_at" />
        <error-messages :errors="errors.sending_at" />
      </v-card-text>
    </v-card>


    <v-card class="mb-4">
      <v-card-title>Previzualizeaza</v-card-title>
      <v-card-text>
        <template v-if="newsletterId">
          <iframe :src="previewLink" height="600px" width="100%"></iframe>
        </template>
      </v-card-text>
    </v-card>


    <v-card class="mb-4">
      <v-card-title>Actiuni</v-card-title>
      <v-card-text>
        <v-btn :disabled="is_saving" class="mr-2" color="green" dark @click="sendNewsletter">
          <template v-if="form.sending_at">Programeaza newsletterul</template>
          <template v-else>Trimite newsletter</template>
        </v-btn>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>


import SendingAtSelect from './SendingAtSelect'
import FieldsEditor from './Fields'
import ClientSelect from './ClientSelect'
import ProductsSelect from './ProductsSelect'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import { debounce } from 'lodash'

export default {
  name: 'NewNewsletter',
  components: {
    ErrorMessages,
    FieldsEditor,
    SendingAtSelect,
    ClientSelect,
    ProductsSelect
  },
  data() {
    return {
      iframeBump: 0,
      is_saving: false,
      newsletterId: null,
      form: {
        subject: '',
        reply_to: 'distributie@citgrup.ro',
        from_name: 'CIT Grup',
        use_franchisee_names: 1,
        products: [],
        target: {
          recipient_type: 'citgrup_clients',
          citgrup_clients: {
            client_type: [],
            interests: [],
            // franchiseeClient: []
          }
        },
        franchisee_target: {
          recipient_type: 'citgrup_clients',
          citgrup_clients: {
            client_type: [],
            interests: [],
          }
        },
        fields: [],
        sending_at: ''
      },
      errors: {}
    }
  },
  methods: {
    sendNewsletter() {
      this.is_saving = true
      this.errors = {}
      this.$http.post(`newsletter/${this.newsletterId}`, this.form)
        .then(({ data }) => {
          console.log(data)
          this.$router.push({ name: 'newsletter' })
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.errors = response.data.errors
          }

        })
        .finally(() => {
          this.is_saving = false
        })
    },
    saveNewsletter: debounce(function () {
      this.is_saving = true
      this.errors = {}
      if (this.newsletterId) {
        this.updateExistingNewsletter()
      } else {
        this.createNewNewsletter()
      }
    }, 500),
    createNewNewsletter() {
      this.$http.post('newsletter', this.form)
        .then(({ data }) => {
          console.log(data)
          this.newsletterId = data.id
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.errors = response.data.errors
          }
        })
        .finally(() => {
          this.is_saving = false
          this.iframeBump++
        })
    },
    updateExistingNewsletter() {
      this.$http.put(`newsletter/${this.newsletterId}`, this.form)
        .then(({ data }) => {
          console.log(data)
          this.newsletterId = data.id
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.errors = response.data.errors
          }
        })
        .finally(() => {
          this.is_saving = false
          this.iframeBump++
        })
    }
  },
  watch: {
    form: {
      immediate: false,
      deep: true,
      handler() {
        this.saveNewsletter()
      }
    }
  },
  computed: {
    previewLink() {
      const baseURL = process.env.VUE_APP_BASE_URL_API
      return `${baseURL}/app/newsletter/preview/${this.newsletterId}?v=${this.iframeBump}`
    }
  }
}
</script>
