<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        block
        color="red lighten-2"
        dark
      >
        Adauga produse bulk
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 ">
        Adauga mai multe produse dupa Id
      </v-card-title>

      <v-card-text>
        <v-text-field v-model="products" placeholder="ex. 1245,4256,245"/>

        * Maxim 50 produse pot fi incarcate odata
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn :disabled="loading" text @click="cancel">
          Anuleaza
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          :loading="loading"
          color="primary"
          @click="confirmSelection"
          class="primary-button"
        >
          Adauga produsele
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BulkAddProductsById',
  data () {
    return {
      dialog: false,
      loading: false,
      products: ''
    }
  },
  computed: {
    cleanedUpProducts () {
      return this.products.replace(/\s/g, '')
    }
  },
  methods: {
    manualAdd (idList) {
      this.products = idList
      this.confirmSelection()
    },
    confirmSelection () {
      this.loading = true
      this.$http.get(`products?page_size=50&filter[id]=${this.cleanedUpProducts}`)
        .then(({data}) => {
          if (data.data.length > 0) {
            const products = data.data
            // noinspection EqualityComparisonWithCoercionJS
            const orderedProducts = this.cleanedUpProducts
              .split(',')
              .map(item => products.find(product => product.id == item))
              .filter(item => typeof item !== 'undefined')
            this.$emit('added', orderedProducts)
          }
        })
        .finally(() => {
          this.loading = false
          this.dialog = false
        })
    },
    cancel () {
      this.products = ''
      this.dialog = false
    }
  }
}
</script>
