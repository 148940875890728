<template>
  <div>
    <v-switch v-model="postponeSending" label="Programeaza trimiterea"/>
    <v-datetime-picker
      v-if="postponeSending"
      v-model="datetime"
      :time-picker-props="{format:'24hr'}"
      label="Selecteaza data si ora"
    />
  </div>
</template>

<script>
export default {
  name: 'SendingAtSelect',
  data () {
    return {
      postponeSending: false,
      datetime: ''
    }
  },
  watch: {
    postponeSending (value) {
      if (!value) {
        this.datetime = ''
      }
    },
    datetime (value) {
      this.$emit('input', value)
    }
  }
}
</script>
